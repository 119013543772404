import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  flex-grow: 1;
  background: ${props =>
    props.background ? props.theme.colors[props.background] : 'initial'};
`

const ContainerFullWidth = props => {
  return (
    <Wrapper background={props.background}>
      {props.children}
    </Wrapper>
  )
}

export default ContainerFullWidth
