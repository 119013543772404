import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const StyledFullScreenWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  overflow: hidden;
`
const BackgroundSection = ({ className, children }) => {
  const { bgImg } = useStaticQuery(
    graphql`
      query {
        bgImg: file(relativePath: { eq: "services_page_low.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const imageData = bgImg.childImageSharp.fluid
  return (
  <StyledFullScreenWrapper>
    <BackgroundImage Tag="section" className={className} fluid={imageData}>
      {children}
    </BackgroundImage>
  </StyledFullScreenWrapper>
)
}

const ServicesPageBackground = styled(BackgroundSection)`
	display: flex;
	justify-content: center;
    align-items: center;
	flex-direction: column;

	width: 100%;
	height: auto;
	// flex: 1;
	// width: 100vw;

    padding: 4rem 1rem 5rem 1rem;

    /* For medium and up */
    @media screen and (min-width: ${props => props.theme.responsive.large}) {
        padding: 5rem 1rem 6rem 1rem;
    }
    /* For tablet; up to medium */
    @media screen and (max-width: ${props => props.theme.responsive.medium}) {
        &:after,
        &:before {
        background-size: cover;
        }
        padding: 4rem 1rem 5rem 1rem;
    }
    /* For mobile */
	@media screen and (max-width: ${props => props.theme.responsive.small}) {
        &:after,
        &:before {
            background-size: cover;
        }
        padding: 3rem 0.5rem 4rem 0.5rem;
	}
`
export default ServicesPageBackground