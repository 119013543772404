import React from 'react'
import Layout from '../../components/Layout'
import ContainerFullWidth from '../../components/ContainerFullWidth'
import SEO from '../../components/SEO'
import ServicesPage from '../../components/Info/ServicesPage'

const Services = ({ data }) => {
  return (
    <Layout>
      <SEO title="예배 안내 | 안내 - 죠이플 교회" description="Service schedule and location addresses" />
      <ContainerFullWidth background={'background'}>
        <ServicesPage />
      </ContainerFullWidth>
    </Layout>
  )
}

export default Services
